import React, { MouseEventHandler } from "react";
import styled from "styled-components";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import useUser from "hooks/useUser";
import Login from "./Login";

const CashContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 30px;
  padding: 4px 10px;
  background: #222;
  border-radius: 3px;
  font-size: 20px;

  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 30px;
  cursor: pointer;
`;

const UserName = styled.p`
  margin-right: 10px;
  font-size: 18px;
`;

const formatQuantity = (quantity: number) =>
  new Intl.NumberFormat().format(quantity);

const UserMenu = () => {
  const user = useUser();
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | undefined>(
    undefined
  );

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  if (user?.logged) {
    return (
      <>
        <Tooltip title="Ribeirinhos">
          <CashContainer>
            <img src="/images/cash-icon.png" />
            {formatQuantity(user.cash)}
          </CashContainer>
        </Tooltip>
        <UserContainer onClick={handleClick}>
          <UserName>{user.name}</UserName>
          <Avatar src={user.avatar} alt={user.name} />
        </UserContainer>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          keepMounted
        >
          {user.isMod && (
            <MenuItem component="a" href="/admin">
              Admin
            </MenuItem>
          )}
          <MenuItem component="a" href="/account/whitelists">
            Allowlists
          </MenuItem>
          <MenuItem component="a" href="/account/vip">
            VIP
          </MenuItem>
          <MenuItem component="a" href="/account/cash">
            Ribeirinhos
          </MenuItem>
          <MenuItem style={{ width: "140px" }} component="a" href="/api/logout">
            Sair
          </MenuItem>
        </Menu>
      </>
    );
  }

  return (
    <UserContainer>
      <Login />
    </UserContainer>
  );
};

export default UserMenu;
