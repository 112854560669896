import React from "react";
import styled from "styled-components";
import Link from "next/link";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import IconButton from "@material-ui/core/IconButton";

const StyledIconButton = styled(IconButton)`
  margin-left: 10px;

  @media (max-width: 800px) {
    display: none;
  }
`;

const SocialMenu = () => {
  return (
    <>
      <Link href="https://www.instagram.com/rprp_city/" passHref>
        <StyledIconButton>
          <InstagramIcon />
        </StyledIconButton>
      </Link>
      <Link href="https://twitter.com/ribeiraocityrp" passHref>
        <StyledIconButton>
          <TwitterIcon />
        </StyledIconButton>
      </Link>
      <Link href="https://www.youtube.com/user/Denissnider" passHref>
        <StyledIconButton>
          <YouTubeIcon />
        </StyledIconButton>
      </Link>
    </>
  );
};

export default SocialMenu;
