import React from "react";
import getConfig from "next/config";
import dynamic from "next/dynamic";
import Button, { ButtonProps } from "@material-ui/core/Button";
import Link from "next/link";

const Login = (props: ButtonProps & { text?: string }) => {
  return (
    <Link href="/termos" passHref>
      <Button {...props}>{props.text || "Entrar"}</Button>
    </Link>
  );
};

export default dynamic(() => Promise.resolve(Login), {
  ssr: false,
});
