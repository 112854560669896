import React from "react";
import useUser from "hooks/useUser";
import { useRouter } from "next/router";
import styled from "styled-components";
import AdminMenu from "./AdminMenu";
import Header from "./Header";
import { DefaultBackground } from "./Background";

const Container = styled.div`
  // height: calc(100% - 66px);
  height: 100%;
  display: flex;
`;

const Content = styled.div`
  flex: 1;
  height: 100%;
  overflow-x: hidden;
  padding-top: 66px;
`;

type Layout = {
  children: React.ReactNode;
};

const Layout: React.FC<Layout> = ({ children }) => {
  const user = useUser();
  const router = useRouter();

  return (
    <>
      <Header />
      <Container>
        {router.pathname.startsWith("/admin") &&
          (user?.isAdmin || user?.isMod) && <AdminMenu />}

        <DefaultBackground />
        <Content id="scroll-container">{children}</Content>
      </Container>
    </>
  );
};

export default Layout;
