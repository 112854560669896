import styled from "styled-components";
import React from "react";

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top:0;
  background: url('/images/home-background.png') no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: rgba(0,0,0,0.5);
  z-index: -5;
`
const Gradient = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top:0;
  background: linear-gradient(0deg, rgba(0,0,0,0.99) 0%, rgba(0,0,0,0.85) 100%);
  backdrop-filter: blur(15px);
  z-index: -2;
`

export const DefaultBackground = () => {
    return (
        <>
            <Background />
            <Gradient />
        </>
    )
}