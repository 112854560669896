import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html, body, #__next {
    height: 100%;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export default GlobalStyle;
